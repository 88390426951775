/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import useGetLocationsFromId from '@holmanfm/lib/hooks/assets/use-get-locations-from-id';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import usePermissions from '@holmanfm/lib/hooks/use-permissions';
import useDialog from '@holmanfm/lib/hooks/use-dialog';
import { formatDate } from '@holmanfm/lib/util/moment';
import { getVehicleTypeList, updateAsset } from '@holmanfm/lib/services/assets';
import useVinDecoding from '@holmanfm/lib/hooks/assets/use-vin-decoding';
import { useNSTranslation } from '@holmanfm/lib/lang';
import {
  getAssetGroups,
  postAssetToGroup,
  deleteAssetFromAssetGroup,
} from '@holmanfm/lib/services/fuel';
import AddAssetHelpers from '@holmanfm/lib/common/add-asset-helpers';
import Features from '@holmanfm/lib/lib-global';
import useAsync from '@holmanfm/lib/hooks/use-async';
import { purchaseTypeConvert } from '@holmanfm/lib/common/marketplace/asset';
import TextField from '~/shared/components/forms/text-field';
import Select, { MenuItem } from '~/shared/components/forms/select';
import Button from '~/shared/components/atom/button';
import makeStyles from '~/shared/components/makeStyles';
import InputAdornment from '~/shared/components/input-adornment';
import LinkText from '~/shared/components/text-link';
import CircularProgress from '~/shared/components/circular-progress';
import VinDialog from '~/areas/assets/add-components/vin-dialog';
import Fade from '~/shared/components/transitions/fade';
import AssetLocation from '~/areas/assets/update-page/components/asset-location';
import ProgressButton from '~/shared/components/progress-btn';
import AssetImgUpload from '~/areas/assets/asset-img-upload';
import { useToasts } from '~/shared/components/withToast';
import vinHelperText from '~/areas/assets/vin-helper-text';
import ServerMessages from '~/shared/components/messages/server-messages';

const useStyles = makeStyles(theme => ({
  editRows: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const AssetDetailsEditForm = props => {
  const { data, closeEditing, setHasDataUpdated, hasDataUpdated } = props;
  const classes = useStyles();
  const { t } = useNSTranslation('individualAsset', 'overview');
  const { canAccessFeature } = usePermissions();
  const [ApiError, setApiError] = React.useState(null);

  const { open, openDialog, closeDialog } = useDialog();

  const { imageUploadAndSave } = AddAssetHelpers;

  const { vinOnChange, isDecodingVin, isVinDecoded, vinError } = useVinDecoding(
    data || {}
  );

  const isAriOwned = data?.third_party_lease_type === '4';

  const tree = useSelector(state => selectors.getOrgNodesTree(state));
  const selectedLevel = useGetLocationsFromId(data?.org_node_id);
  const orgLevels = useSelector(state => selectors.getOrgLevels(state));
  const [vehicleTypeList, setVehicleTypeList] = React.useState();
  const { error } = useToasts();

  const {
    run: assetGroupRun,
    data: assetGroupsData,
    error: assetGroupError,
  } = useAsync();

  const getTypeLists = async () => {
    const fullList = await getVehicleTypeList({ all: true });
    const selectList = await getVehicleTypeList();
    setVehicleTypeList({ all: fullList.payload, select: selectList.payload });
  };

  const getAssetGroupData = () => {
    if (
      canAccessFeature(Features.FUEL_MANAGEMENT.MANAGE_FUEL_PROFILES) &&
      !assetGroupsData
    ) {
      assetGroupRun(getAssetGroups().then(res => res.payload));
    }
  };

  React.useEffect(() => {
    getAssetGroupData();
  }, []);

  React.useEffect(() => {
    getTypeLists();
  }, []);

  let adornment;

  if (isVinDecoded) {
    adornment = {
      endAdornment: (
        <InputAdornment position="end">
          <LinkText
            style={{ fontSize: 12, fontWeight: 'normal' }}
            onClick={openDialog}
          >
            {t('view-vin-data')}
          </LinkText>
        </InputAdornment>
      ),
    };
  }

  if (isDecodingVin) {
    adornment = {
      endAdornment: (
        <InputAdornment position="end">
          <CircularProgress inlineStyle size={24} />
        </InputAdornment>
      ),
    };
  }

  return (
    <Formik
      initialValues={{
        assetNo: data?.asset_no,
        vin: data?.vin,
        orgNodeId: data?.org_node_id,
        hierarchyLevel1: selectedLevel?.level1?.id || '',
        hierarchyLevel2: selectedLevel?.level2?.id || '',
        hierarchyLevel3: selectedLevel?.level3?.id || '',
        assetStatus: data?.active,
        odometer: data?.latest_odometer || '',
        year: data?.year,
        make: data?.make,
        model: data?.model,
        trim: data?.trim || '',
        series: data?.series || '',
        vehicleType: data?.vehicleType || data?.vehicle_type,
        exterior: data?.exterior_color || '',
        interior: data?.interior_color || '',
        assetGroup: data?.assetGroupName || '',
        hasAssetGroupAssigned: {
          assigned: Boolean(data?.assetGroupName),
          groupName: data?.assetGroupName,
        },
        monthsInService: data?.mis || '',
        orderDate: data?.order_date || '',
        orderType: data?.order_type || '',
        createdDate: data?.created_at || '',
        fuelCapacity: data?.fuel_capacity || '',
        fuelCapacityUom: data?.fuel_capacity_uom || '',
        customStatus: data?.customer_asset_status || '',
        vinDecodedData: data?.vin_decoded_data,
        photoUrl: data?.photo_urls,
        purchaseType: purchaseTypeConvert[data?.third_party_lease_type] || '',
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        setSubmitting(true);
        let selectedAssetGroup;
        let previousGroupId;
        if (values?.hasAssetGroupAssigned?.assigned) {
          previousGroupId = assetGroupsData.filter(
            ag => ag.name === values?.hasAssetGroupAssigned?.groupName
          )?.[0]?.id;
        }
        if (values.assetGroup.length > 0) {
          selectedAssetGroup = assetGroupsData.filter(
            agg => agg.name === values.assetGroup
          )?.[0];
        }
        const updatedData = {
          id: data?.id,
          assetNo: values.assetNo,
          orgNodeId: values.orgNodeId,
          vin: values.vin,
          year: values.year,
          make: values.make,
          model: values.model,
          trim: values.trim,
          series: values.series,
          vehicleType: values.vehicleType,
          exteriorColor: values.exterior,
          interiorColor: values.interior,
          customerAssetStatus: values.customStatus,
          latestOdometer: values.odometer,
          active: values.assetStatus,
          vinDecodedData: values.vinDecodedData,
        };
        updateAsset(updatedData)
          .then(async res => {
            if (
              values?.hasAssetGroupAssigned?.assigned &&
              canAccessFeature(Features.FUEL_MANAGEMENT.MANAGE_FUEL_PROFILES)
            ) {
              if (values?.assetGroup === '') {
                // delete
                await deleteAssetFromAssetGroup(
                  previousGroupId,
                  res?.payload?.entity?.id
                );
              } else {
                // delete then post
                await deleteAssetFromAssetGroup(
                  previousGroupId,
                  res?.payload?.entity?.id
                );
                setTimeout(async () => {
                  await postAssetToGroup(
                    selectedAssetGroup?.id,
                    res?.payload?.entity?.id
                  );
                }, 1000);
              }
            }
            if (
              !values?.hasAssetGroupAssigned?.assigned &&
              values?.assetGroup.length > 0
            ) {
              await postAssetToGroup(
                selectedAssetGroup?.id,
                res?.payload?.entity?.id
              );
            }
            setSubmitting(false);
            if (res?.payload?.valid) {
              if (values?.photoUrl && !Array?.isArray(values.photoUrl)) {
                await imageUploadAndSave(updatedData, values.photoUrl, error);
              }
            }
            setTimeout(() => {
              setHasDataUpdated(!hasDataUpdated);
              closeEditing();
            }, 3000);
          })
          .catch(err => {
            // if validation error
            if (err?.[0]?.code === 'validation-failed') {
              const errorContent = err?.[0]?.message;
              const errKey = Object.keys(errorContent)[0];
              const errMessage = errorContent[errKey][0];
              setFieldError(errKey, errMessage);
            }
            setSubmitting(false);
            // generic errors
            setApiError(err);
          });
      }}
    >
      {FormikBag => {
        const {
          handleSubmit,
          values,
          setFieldValue,
          setFieldError,
          handleChange,
          errors,
          isSubmitting,
          isValid,
        } = FormikBag;

        const vehicleTypeListSelection = values.vinDecodedData
          ? vehicleTypeList?.all
          : vehicleTypeList?.select;

        const showHelperText = vinHelperText(
          Boolean(vinError),
          vinError,
          false,
          AddAssetHelpers.isEmptyInput(values.vinNo),
          t
        );

        const completedForm =
          (values?.assetNo !== '' || (values?.vin !== '' && !vinError)) &&
          (!isVinDecoded ? values?.vehicleType !== '' : true);

        return (
          <Form>
            {assetGroupError && (
              <div className={classes.editRows}>
                <ServerMessages messages={assetGroupError} />
              </div>
            )}
            {ApiError && (
              <div className={classes.editRows}>
                <ServerMessages messages={ApiError} />
              </div>
            )}
            <div className={classes.editRows}>
              <TextField
                name="assetNo"
                label={t('asset-no')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <TextField
                name="year"
                label={t('year')}
                variant="outlined"
                style={{ flex: 0.35 }}
                disabled={isAriOwned || isVinDecoded}
              />
              <TextField
                name="make"
                label={t('make')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled={isAriOwned || isVinDecoded}
              />
              <TextField
                name="model"
                label={t('model')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled={isAriOwned || isVinDecoded}
              />
              <TextField
                name="trim"
                label={t('trim')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled={isAriOwned || isVinDecoded}
              />
              <TextField
                name="series"
                label={t('series')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled={isAriOwned || isVinDecoded}
              />
            </div>
            <div className={classes.editRows}>
              <TextField
                name="vin"
                label={t('vin')}
                variant="outlined"
                style={{ flex: 1.025 }}
                InputProps={adornment}
                disabled={isAriOwned || isDecodingVin}
                onChange={e =>
                  vinOnChange(
                    e,
                    values,
                    setFieldValue,
                    handleChange,
                    setFieldError
                  )
                }
                inputProps={{
                  maxLength: 17,
                }}
                error={Boolean(vinError)}
                helperText={showHelperText}
              />
              {isVinDecoded ? (
                <TextField
                  name="vehicleType"
                  label={t('vehicle-type')}
                  variant="outlined"
                  style={{ flex: 1.55 }}
                  disabled={isAriOwned || isVinDecoded}
                />
              ) : (
                <Select
                  name="vehicleType"
                  id="vehicleType"
                  required
                  variant="outlined"
                  label="Vehicle Type"
                  displayEmpty
                  notched
                  style={{ flex: 1.55 }}
                >
                  <MenuItem value="">{t('add-vehicle-type')}</MenuItem>
                  {vehicleTypeListSelection?.map(type => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              )}
              <TextField
                name="exterior"
                label={t('exterior-color')}
                variant="outlined"
                style={{ flex: 0.75 }}
                disabled={isAriOwned}
              />
              <TextField
                name="interior"
                label={t('interior')}
                variant="outlined"
                style={{ flex: 0.75 }}
                disabled={isAriOwned}
              />
            </div>
            <div className={classes.editRows}>
              <Select
                name="assetStatus"
                id="assetStatus"
                label={t('asset-status')}
                variant="outlined"
                displayEmpty
                style={{ flex: 1 }}
                disabled={isAriOwned}
              >
                <MenuItem value>{t('active')}</MenuItem>
                <MenuItem value={false}>{t('inactive')}</MenuItem>
              </Select>
              <TextField
                name="odometer"
                label={t('current-odometer')}
                variant="outlined"
                style={{ flex: 1 }}
              />
              {canAccessFeature(
                Features.FUEL_MANAGEMENT.MANAGE_FUEL_PROFILES
              ) ? (
                <Select
                  name="assetGroup"
                  id="assetGroup"
                  label={t('asset-group')}
                  variant="outlined"
                  displayEmpty
                  notched
                  style={{ flex: 1 }}
                >
                  <MenuItem value="">{t('select-an-asset-group')}</MenuItem>
                  {assetGroupsData?.map(ag => (
                    <MenuItem key={ag.id} value={ag.name}>
                      {ag.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <div style={{ flex: 1 }} />
              )}
              <TextField
                name="customStatus"
                label={t('custom-status')}
                variant="outlined"
                notched
                style={{ flex: 0.88 }}
              />
              <div style={{ flex: 0.88 }} />
            </div>
            <div className={classes.editRows}>
              <AssetImgUpload
                data={{
                  vehicleType: data?.vehicleType,
                  bodyType: data?.bodyType,
                  body_type: data?.body_type,
                  vehicle_type: data?.vehicle_type,
                }}
                assetImg={values?.photoUrl}
                setAssetImg={assignedImg =>
                  setFieldValue('photoUrl', assignedImg)
                }
              />
            </div>
            <div style={{ marginBottom: 24, display: 'block' }}>
              <AssetLocation orgLevels={orgLevels} tree={tree} />
            </div>
            <div className={classes.editRows}>
              <TextField
                name="monthsInService"
                label={t('mis')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <TextField
                value={
                  values?.orderDate ? formatDate(values.orderDate) : '\u00A0'
                }
                name="orderDate"
                label={t('order-date')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <TextField
                name="orderType"
                label={t('order-type')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <TextField
                value={formatDate(values.createdDate)}
                name="createdDate"
                label={t('created-date')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <div style={{ flex: 0.35 }} />
            </div>
            <div className={classes.editRows}>
              <TextField
                name="fuelCapacity"
                label={t('fuel-capacity')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <TextField
                name="fuelCapacity"
                label={t('fuel-capacity-uom')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <TextField
                name="geotabDeviceId"
                label={t('geotab-device-id')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <TextField
                name="purchaseType"
                label={t('purchase-type')}
                variant="outlined"
                style={{ flex: 1 }}
                disabled
              />
              <div style={{ flex: 0.35 }} />
            </div>
            <div>
              <ProgressButton
                btnTitle={t('update-asset-details')}
                submitFunc={handleSubmit}
                processing={isSubmitting}
                disabled={
                  !completedForm ||
                  !isValid ||
                  vinError ||
                  errors?.vinNo ||
                  errors?.vin ||
                  isSubmitting
                }
              />
              <Button onClick={closeEditing} variant="text" color="secondary">
                {t('cancel')}
              </Button>
            </div>
            {isVinDecoded && open && (
              <VinDialog
                data={
                  typeof values?.vinDecodedData === 'object'
                    ? values?.vinDecodedData
                    : JSON.parse(values?.vinDecodedData)?.Results?.[0]
                }
                isDialogOpen={open}
                Transition={Transition}
                closeForm={closeDialog}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

AssetDetailsEditForm.propTypes = {
  data: PropTypes.shape({}).isRequired,
  closeEditing: PropTypes.func.isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
};

export default AssetDetailsEditForm;
