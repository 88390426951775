/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import CommonColors from '@holmanfm/lib/common/colors';
import AssetIconUtils from '@holmanfm/lib/util/asset-icons';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Tabs from '~/shared/components/tabs';
import Tab from '~/shared/components/tab';
import Typography from '~/shared/components/atom/typography/typography';
import makeStyles from '~/shared/components/makeStyles';
import ShowAssetLocation from '~/areas/individual-assets/show-asset-location';
import withStyles from '~/shared/components/withStyles';

const useStyles = makeStyles(theme => ({
  assetHeaderData: {
    background: theme.palette.backgroundColors.tan,
    padding: theme.spacing(2, 2, 0, 2),
    display: 'flex',
    flexDirection: 'column',
  },
  dateHeaderText: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  HeaderDataSubtitles: {
    padding: theme.spacing(2),
  },
  vehicleIconBox: {
    paddingLeft: 0,
    width: '80px',
    '& img': {
      backgroundColor: '#fff',
      border: '1px solid #efefef',
      padding: theme.spacing(2),
      width: '80px',
      height: '60px',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100px',
      '& img': {
        width: '100px',
        height: '100px',
      },
    },
  },
  vehicleImg: {
    width: 100,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      height: 60,
    },
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
}));

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'capitalize',
    '&$selected': {
      color: theme.palette.body,
      fontWeight: 700,
    },
    '&:focus': {
      color: theme.palette.body,
    },
  },
  selected: {},
}))(Tab);

const StyledTabs = withStyles(theme => ({
  indicator: {
    backgroundColor: theme.palette.tertiary.main,
    height: 6,
  },
}))(Tabs);

const red = CommonColors.sharedPalette.error.main;
const fieldGreen = CommonColors.sharedPalette.tertiary.main;

const HeaderData = props => {
  const {
    data,
    handleTabChange,
    tabValue,
    hasFuelService,
    hasMaintenanceService,
  } = props;
  const classes = useStyles();
  const { t } = useNSTranslation('individualAsset', 'overview');
  const { t: tTabs } = useNSTranslation('individualAsset', 'tabs');
  const hasOdometerData = data?.odometerMiles || data?.latest_odometer;
  const activeString = data?.active ? t('active') : t('inactive');
  const statusColor = data?.active ? { color: fieldGreen } : { color: red };

  return (
    <div className={classes.assetHeaderData}>
      <div className={classes.dateHeaderText}>
        <div
          className={
            data?.photo_urls ? classes.vehicleImg : classes.vehicleIconBox
          }
        >
          <img
            alt={data?.vehicleType || ''}
            src={
              data?.photo_urls ||
              AssetIconUtils.getVehicleAssetIcon(
                data?.vehicle_type || '',
                data?.body_type || ''
              )
            }
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="h2" style={{ paddingLeft: 16 }}>
              {data?.year} {data?.make} {data?.model} {data?.trim}{' '}
              {data?.series}
            </Typography>
            <div style={{ display: 'flex' }}>
              <div className={classes.HeaderDataSubtitles}>
                {data?.asset_no}
              </div>
              <div className={classes.HeaderDataSubtitles} style={statusColor}>
                <strong>{activeString}</strong>
              </div>
              <div className={classes.HeaderDataSubtitles}>{data?.vin}</div>
              <div className={classes.HeaderDataSubtitles}>
                {hasOdometerData ? `${data?.latest_odometer} miles` : 'No Data'}
              </div>
              <div className={classes.HeaderDataSubtitles}>
                <ShowAssetLocation orgNodeId={data?.org_node_id} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <StyledTabs
        value={tabValue}
        indicatorColor="secondary"
        textColor="secondary"
        onChange={handleTabChange}
      >
        <StyledTab value="overview" label="Overview" />
        {hasFuelService && <StyledTab value="fuel" label={tTabs('fuel')} />}
        {hasMaintenanceService && (
          <StyledTab value="maintenance" label={tTabs('maintenance')} />
        )}
        <StyledTab value="odometer" label={tTabs('odometer')} />
        {/* <StyledTab label="Financial" /> */}
        <StyledTab value="documents" label={tTabs('documents')} />
      </StyledTabs>
    </div>
  );
};

HeaderData.propTypes = {
  data: PropTypes.shape({}).isRequired,
  handleTabChange: PropTypes.func.isRequired,
  tabValue: PropTypes.string.isRequired,
  hasFuelService: PropTypes.bool.isRequired,
  hasMaintenanceService: PropTypes.bool.isRequired,
};

export default HeaderData;
