import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Typography from '~/shared/components/atom/typography';
import StyledDialog from '~/shared/components/styled-dialog';

const UpdateEstimateDialog = props => {
  const { open, closeDialog, updateError, estimateNumber } = props;
  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');

  return (
    <StyledDialog
      isOpen={open}
      onClose={closeDialog}
      dialogTitle={t('update-estimate')}
      btnActions={{}}
    >
      {updateError || (
        <Typography variant="body2">
          {t('estimate-has-been-updated', { estimateNo: estimateNumber })}
        </Typography>
      )}
    </StyledDialog>
  );
};

UpdateEstimateDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  updateError: PropTypes.shape({}),
  estimateNumber: PropTypes.string.isRequired,
};

UpdateEstimateDialog.defaultProps = {
  updateError: undefined,
};

export default UpdateEstimateDialog;
