import { connect, getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import PhoneInput from '../inputs/phone-input-2';
import FormHelperText from '~/shared/components/form-helper-text';

const FormPhoneInput = props => {
  const {
    name,
    label,
    formik: { errors, touched, values, handleChange, handleBlur },
    onChange,
    helperText,
    ...rest
  } = props;

  const error = getIn(errors, name);
  const touch = getIn(touched, name);
  const value = getIn(values, name);

  let updatedProps = {
    name,
    label,
    value,
    onChange: onChange || handleChange,
    onBlur: handleBlur,
    ...rest,
  };

  if (error && touch) {
    updatedProps = {
      ...updatedProps,
      error: true,
      helperText: error,
    };
  }

  return (
    <>
      <PhoneInput {...updatedProps} style={{ paddingBottom: 0 }} />
      {error && touch && (
        <FormHelperText error style={{ paddingBottom: 16 }}>
          {error}
        </FormHelperText>
      )}
      {helperText && (
        <FormHelperText style={{ margin: '-1.25em 0 1em 1em' }}>
          {helperText}
        </FormHelperText>
      )}
    </>
  );
};

FormPhoneInput.propTypes = {
  formik: PropTypes.shape({
    errors: PropTypes.shape({}).isRequired,
    touched: PropTypes.shape({}).isRequired,
    values: PropTypes.shape({}).isRequired,
    handleChange: PropTypes.func.isRequired,
    handleBlur: PropTypes.func.isRequired,
  }).isRequired,

  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.string,
};

FormPhoneInput.defaultProps = {
  onChange: undefined,
  label: undefined,
  helperText: undefined,
};

export default connect(FormPhoneInput);
