import React from 'react';
import PropTypes from 'prop-types';
import MuiPagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';
import makeStyles from '~/shared/components/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiPagination-root': {
      backgroundColor: 'white',
      boxShadow: 'none',
      '& .MuiPagination-ul': {
        justifyContent: 'center',
        '& button': {
          '&:focus': {
            backgroundColor: 'inherit !important',
          },
        },
      },
    },
  },
}));

const Pagination = ({ page, totalPages, handlePageChange, showLastButton }) => {
  const classes = useStyles();

  const hasPages = totalPages !== Infinity && totalPages > 1;

  const renderItem = item => {
    // If the item is the last page, don't render it.
    if (!showLastButton && item.type === 'page' && item.page === totalPages) {
      return null;
    }

    return <PaginationItem {...item} />;
  };

  return (
    <div className={classes.root}>
      {hasPages && (
        <MuiPagination
          count={totalPages}
          variant="outlined"
          shape="rounded"
          size="large"
          color="secondary"
          showFirstButton
          showLastButton={showLastButton}
          onChange={(_, p) => handlePageChange(p)}
          page={page}
          renderItem={renderItem}
        />
      )}
    </div>
  );
};

Pagination.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  showLastButton: PropTypes.bool,
};
Pagination.defaultProps = {
  showLastButton: true,
};

export default React.memo(Pagination);
