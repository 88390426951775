/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { formatDate } from '@holmanfm/lib/util/moment';
import { useNSTranslation } from '@holmanfm/lib/lang';
import useAsync from '@holmanfm/lib/hooks/use-async';
import { getAssetDocuments } from '@holmanfm/lib/services/documents';
import {
  getChildAssetPhotos,
  getChildDocuments,
} from '@holmanfm/lib/services/parent-org';
import Typography from '~/shared/components/atom/typography/typography';
import HSBSTableHeader from '~/areas/fleet-assets/hsbs-table/hsbs-table-header';
import HSBSTableCell from '~/areas/fleet-assets/hsbs-table/hsbs-table-cell';
import HSBSTable from '~/areas/fleet-assets/hsbs-table/hsbs-table';
import HSBSTableRow from '~/areas/fleet-assets/hsbs-table/hsbs-table-row';
import LinkComponent from '~/shared/components/link';
import VerticalAlignBottomIcon from '~/shared/icons/vertical-align-botton';
import ResourceFileManager from '~/shared/components/file-manager/resource-file-manager';
import CircularProgress from '~/shared/components/circular-progress';
import ServerMessages from '~/shared/components/messages/server-messages';

const ChildAssetPhotos = props => {
  const { childPhotos } = props;
  return (
    <div>
      {childPhotos?.map(file => (
        <div
          key={file.name}
          style={{
            display: 'inline-block',
            position: 'relative',
            width: '12.5%',
            paddingBottom: '12.5%',
            height: 0,
          }}
        >
          <div
            style={{
              backgroundImage: `url(${file.downloadUrl})`,
              position: 'absolute',
              top: '10%',
              right: '10%',
              bottom: '10%',
              left: '10%',
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              backgroundRepeat: 'no-repeat',
            }}
            role="button"
          />
        </div>
      ))}
    </div>
  );
};

ChildAssetPhotos.propTypes = {
  childPhotos: PropTypes.arrayOf(PropTypes.shape({})),
};

ChildAssetPhotos.defaultProps = {
  childPhotos: undefined,
};

const DocumentsPhotosTab = props => {
  const { asset, parentOrg, childOrgId } = props;
  const { t } = useNSTranslation('individualAsset', 'documentsPhotos');
  const [documentFiles, setDocumentFiles] = React.useState([]);
  const [photos, setPhotos] = React.useState([]);

  const { data: allDocuments, run, status, error } = useAsync();

  const {
    data: childPhotos,
    run: childPhotoRun,
    status: childStatus,
    error: childError,
  } = useAsync();

  React.useEffect(() => {
    if (parentOrg) {
      run(getChildDocuments(childOrgId, asset?.id).then(res => res.payload));
    } else {
      run(getAssetDocuments(asset?.id).then(res => res.payload));
    }
  }, [run, asset, parentOrg, childOrgId]);

  React.useEffect(() => {
    if (parentOrg) {
      childPhotoRun(
        getChildAssetPhotos(
          childOrgId,
          'assets',
          asset?.id,
          'customer-photos'
        ).then(res => res.payload)
      );
    }
  }, [childPhotoRun, childOrgId, asset, parentOrg]);

  const headerRows = (
    <>
      <HSBSTableHeader>{t('name-document')}</HSBSTableHeader>
      <HSBSTableHeader>{t('type-document')}</HSBSTableHeader>
      <HSBSTableHeader>{t('date-upload')}</HSBSTableHeader>
      <HSBSTableHeader>&nbsp;</HSBSTableHeader>
    </>
  );

  const bodyRows = doc => (
    <React.Fragment key={doc.filename}>
      <HSBSTableRow>
        <HSBSTableCell>{doc?.filename}</HSBSTableCell>
        <HSBSTableCell>{doc?.type}</HSBSTableCell>
        <HSBSTableCell>{doc?.date ? formatDate(doc?.date) : ''}</HSBSTableCell>
        {parentOrg ? null : (
          <HSBSTableCell>
            <LinkComponent href={doc?.url} download={`${doc.type}`}>
              <VerticalAlignBottomIcon color="primary" />
            </LinkComponent>
          </HSBSTableCell>
        )}
      </HSBSTableRow>
    </React.Fragment>
  );

  if (status === 'pending' || childStatus === 'pending') {
    return <CircularProgress />;
  }
  if (status === 'rejected') {
    return <ServerMessages messages={error} />;
  }
  if (childStatus === 'rejected') {
    return <ServerMessages messages={childError} />;
  }

  return (
    <div>
      <div>
        <Typography variant="h5" style={{ padding: '24px 0' }}>
          {t('photos-asset')}
        </Typography>
        {parentOrg ? (
          <ChildAssetPhotos childPhotos={childPhotos} />
        ) : (
          <ResourceFileManager
            resource="assets"
            resourceId={asset?.id}
            prefix="customer-photos"
            files={photos}
            setFiles={setPhotos}
            multiple
            helperText="Acceptable file types: JPG, PNG, TIFF"
            helperTextStyle={{ marginTop: '-10px' }}
            name="files"
            accept={['image/png', 'image/jpeg', 'image/tiff']}
          />
        )}
      </div>
      <div>
        <Typography variant="h5" style={{ padding: '24px 0' }}>
          {t('vehicle-documents')}
        </Typography>
        {parentOrg ? (
          <HSBSTable
            headerRows={headerRows}
            bodyRows={allDocuments?.map(doc => bodyRows(doc))}
          />
        ) : (
          <>
            <ResourceFileManager
              resource="assets"
              resourceId={asset?.id}
              prefix="customer"
              files={documentFiles}
              setFiles={setDocumentFiles}
              multiple
              helperText="Acceptable file types: JPG, PNG, TIFF, PDF, DOC"
              helperTextStyle={{ marginTop: '-10px' }}
              name="files"
              accept={[
                'application/pdf',
                'application/msword',
                'image/png',
                'image/jpeg',
                'image/tiff',
                'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
              ]}
            />
            <HSBSTable
              headerRows={headerRows}
              bodyRows={allDocuments?.map(doc => bodyRows(doc))}
            />
          </>
        )}
      </div>
    </div>
  );
};

DocumentsPhotosTab.propTypes = {
  asset: PropTypes.shape({}).isRequired,
  parentOrg: PropTypes.bool.isRequired,
  childOrgId: PropTypes.string,
};

DocumentsPhotosTab.defaultProps = {
  childOrgId: undefined,
};

export default DocumentsPhotosTab;
