import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import makeStyles from '~/shared/components/makeStyles';
import AttentionIcon from '~/shared/icons/attention';
import Typography from '~/shared/components/atom/typography';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
  },
  icon: {
    margin: theme.spacing(0, 1, 0.5, 0),
  },
}));

export const MessageCenter = props => {
  const {
    estimateLeasingOptions,
    creditContractStatus,
    newClientInfo,
    creditAssetTypes,
  } = props;
  const classes = useStyles();
  const { t } = useNSTranslation('dealerPortal', 'payment-estimator');

  const renderContent = () => {
    // note: checking for false explicitly to avoid the error message from showing on null - which would happen before the response from api was returned.
    if (creditContractStatus === false || newClientInfo) {
      // the selected client does not have approved credit or does not have contractStartDate
      // or it's a new client
      return (
        <div className={classes.container}>
          <AttentionIcon className={classes.icon} titleAccess="attention" />
          <Typography variant="body2">
            <strong>{t('quoting-disabled')}</strong>
            <br />
            {t('no-credit-contract-disclaimer')}
          </Typography>
        </div>
      );
    }
    if (creditAssetTypes?.length === 0 && !newClientInfo) {
      // a client has been selected, they have a signed contract, but they have no asset types with approved credit
      return (
        <div className={classes.container}>
          <AttentionIcon className={classes.icon} titleAccess="attention" />
          <Typography variant="body2">
            <strong>{t('quoting-disabled')}</strong>
            <br />
            {t('no-asset-type-credit')}
          </Typography>
        </div>
      );
    }
    if (
      estimateLeasingOptions &&
      !estimateLeasingOptions?.creditAssetTypeId &&
      !newClientInfo
    ) {
      // a client with approved credit has been selected, financial info has been entered, but no asset type has been selected
      return (
        <div className={classes.container}>
          <AttentionIcon className={classes.icon} titleAccess="attention" />
          <Typography variant="body2">
            <strong>{t('no-asset-type-selected')}</strong>
            <br />
            {t('select-asset')}
          </Typography>
        </div>
      );
    }
  };

  return <div className={classes.container}>{renderContent()}</div>;
};

MessageCenter.propTypes = {
  estimateLeasingOptions: PropTypes.shape({}),
  creditContractStatus: PropTypes.bool,
  newClientInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  creditAssetTypes: PropTypes.arrayOf(PropTypes.shape({})),
};

MessageCenter.defaultProps = {
  estimateLeasingOptions: null,
  creditContractStatus: null,
  newClientInfo: false,
  creditAssetTypes: null,
};

export default MessageCenter;
