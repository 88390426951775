/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import useAsync from '@holmanfm/lib/hooks/use-async';
import API from '@holmanfm/lib/util/api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { updateUserRequest } from '@holmanfm/lib/services/user';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { isEmptyObject } from '@holmanfm/lib/util/helpers';
import { updateAsset } from '@holmanfm/lib/services/assets';
import TextField from '~/shared/components/forms/text-field';
import Button from '~/shared/components/atom/button';
import makeStyles from '~/shared/components/makeStyles';
import Phone from '~/shared/components/forms/phone';
import RawTextField from '~/shared/components/molecule/text-field';
import Typography from '~/shared/components/atom/typography/typography';
import ProgressButton from '~/shared/components/progress-btn';
import CircularProgress from '~/shared/components/circular-progress';
import ServerMessages from '~/shared/components/messages/server-messages';

const useStyles = makeStyles(theme => ({
  editRows: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    '& > div:not(:last-child)': {
      marginRight: theme.spacing(1),
    },
  },
  inputLabelBold: {
    '& label': {
      fontWeight: 'bold',
    },
    '& legend': {
      fontWeight: 'bold',
    },
  },
}));

const DriverInformationEditForm = props => {
  const { data, closeEditing, setHasDataUpdated, hasDataUpdated } = props;
  const [apiError, setApiError] = React.useState(null);
  const { t } = useNSTranslation('individualAsset', 'overview');

  const getAllUsers = async () => {
    const users = await API.get('/users');
    return users || [];
  };
  const classes = useStyles();

  const { error, data: drivers, status, run } = useAsync();

  React.useEffect(() => run(getAllUsers().then(res => res.payload)), [run]);

  if (status === 'pending') {
    return <CircularProgress inlineStyle size={24} />;
  }

  if (status === 'rejected') {
    return <ServerMessages messages={error} />;
  }

  if (!drivers) {
    return <CircularProgress inlineStyle size={24} />;
  }

  return (
    <Formik
      initialValues={{
        userInfo: isEmptyObject(data?.selected_user)
          ? null
          : data?.selected_user,
      }}
      onSubmit={(values, { setSubmitting, setFieldError }) => {
        setApiError(null);
        if (!values?.userInfo) {
          updateAsset({
            userId: null,
            ...data,
          })
            .then(async () => {
              setSubmitting(false);
              setTimeout(() => {
                setHasDataUpdated(!hasDataUpdated);
                closeEditing();
              }, 3000);
            })
            .catch(err => {
              setSubmitting(false);
              if (err?.[0]?.message === 'waiting for pending') {
                setTimeout(() => {
                  setHasDataUpdated(!hasDataUpdated);
                  closeEditing();
                });
              }
              setFieldError({ err });
              setApiError(err?.[0].message);
            });
          return;
        }
        updateUserRequest(values?.userInfo?.id, {
          assetId: data?.id,
          ...values.userInfo,
        })
          .then(async () => {
            setSubmitting(false);
            setTimeout(() => {
              setHasDataUpdated(!hasDataUpdated);
              closeEditing();
            }, 3000);
          })
          .catch(err => {
            setSubmitting(false);
            if (err?.[0]?.message === 'waiting for pending') {
              setTimeout(() => {
                setHasDataUpdated(!hasDataUpdated);
                closeEditing();
              });
            }
            setFieldError({ err });
            setApiError(err?.[0].message);
          });
      }}
    >
      {FormikBag => {
        const { handleSubmit, setFieldValue, values, isSubmitting } = FormikBag;

        return (
          <Form>
            <div className={classes.editRows}>
              <Autocomplete
                size="small"
                value={values?.userInfo}
                onChange={(_event, newValue) => {
                  if (newValue === null) {
                    setFieldValue('userInfo', null);
                  } else {
                    const driverInfo = drivers.filter(
                      d => d.id === newValue.id
                    );
                    setFieldValue('userInfo', driverInfo?.[0]);
                  }
                }}
                getOptionSelected={option => option?.id}
                getOptionLabel={option =>
                  `${option?.firstName || ''} ${option?.lastName || ''}`
                }
                id="assigned-drivers"
                options={drivers || []}
                style={{ width: 300 }}
                renderInput={params => (
                  <RawTextField
                    {...params}
                    label={t('assigned-driver')}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    placeholder={t('select-driver')}
                    name="assigned-drivers"
                  />
                )}
              />
              <div style={{ flex: 1 }}>
                <Phone
                  name="driverPhoneNumber"
                  label={t('driver-phone-number')}
                  value={values?.userInfo?.phoneNumber || ''}
                  variant="outlined"
                  className={classes.inputLabelBold}
                  inputStyle={{
                    width: '67%',
                  }}
                  disabled
                />
              </div>
              <TextField
                name="driverEmailAddress"
                label={t('driver-email-address')}
                variant="outlined"
                value={values?.userInfo?.email}
                className={classes.inputLabelBold}
                style={{ flex: 1 }}
                disabled
              />
            </div>
            <div>
              <ProgressButton
                btnTitle={t('update-information')}
                submitFunc={handleSubmit}
                processing={isSubmitting}
              />
              <Button onClick={closeEditing} variant="text" color="secondary">
                {t('cancel')}
              </Button>
            </div>
            {apiError && (
              <Typography
                variant="body2"
                style={{ marginTop: 8, color: 'red' }}
              >
                {apiError}
              </Typography>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};

DriverInformationEditForm.propTypes = {
  data: PropTypes.shape({}).isRequired,
  closeEditing: PropTypes.func.isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
};

export default DriverInformationEditForm;
