import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  getOrgInfo,
  sendForEstimate,
  updateEstimate,
  cancelEstimate,
} from '@holmanfm/lib/services/payment-estimator';
import { BASE_URL } from '@holmanfm/lib/common/environment';
import { useToasts } from '~/shared/components/withToast';

const useEstimator = () => {
  const history = useHistory();
  const { error } = useToasts();
  const [creditContractStatus, setCreditContractStatus] = useState(null);
  const [creditAssetTypes, setCreditAssetTypes] = useState();

  const handleRedirect = (type, workflowId, state = undefined) => {
    switch (type) {
      case 'home':
        history.push('/');
        break;

      case 'requestManagement':
        history.push('/customer/request-management', state);
        break;

      case 'quote-request':
        history.push(`/quote-request/${workflowId}`);
        break;

      case 'start-enrollment':
        history.push(`/start-enrollment?workflowId=${workflowId}`);
        break;

      default:
        history.push('/');
        break;
    }
  };

  const updateEstimateData = async (
    leasingOptions,
    chosenOptions,
    assetInformation,
    customerInformation,
    estimateLeasingOptions,
    creditRankUtilized,
    workflowId
  ) => {
    let updatedLeasing;
    const leasingArr = () => {
      if (leasingOptions?.leaseOptions) {
        return leasingOptions?.leaseOptions;
      }
      return leasingOptions;
    };
    if (chosenOptions.length > 0) {
      updatedLeasing = leasingArr().map(lo => {
        if (
          chosenOptions[0].leaseEstimate === lo.leaseEstimate &&
          chosenOptions[0].residual === lo.residual &&
          chosenOptions[0].residualDollars === lo.residualDollars
        ) {
          lo.selected = true;
        } else {
          lo.selected = false;
        }
        return lo;
      });
    } else {
      updatedLeasing = leasingArr().map(lo => {
        lo.selected = false;
        return lo;
      });
    }
    const estimateData = {
      asset: assetInformation,
      org: customerInformation,
      leasing: updatedLeasing,
      financial: estimateLeasingOptions,
      creditRankUtilized,
    };
    try {
      return await updateEstimate(estimateData, workflowId);
    } catch (err) {
      error(err?.[0]?.message);
    }
  };

  const saveEstimateData = async (
    leasingOptions,
    chosenOptions,
    assetInformation,
    customerInformation,
    estimateLeasingOptions
  ) => {
    const combinedList = leasingOptions?.leaseOptions.map(ao => {
      const match = chosenOptions?.some(
        s =>
          s?.leaseEstimate === ao?.leaseEstimate &&
          s?.residual === ao?.residual &&
          s?.residualDollars === ao?.residualDollars
      );
      if (match) {
        ao.selected = true;
      } else {
        ao.selected = false;
      }
      return ao;
    });
    const estimateData = {
      asset: assetInformation,
      org: customerInformation,
      leasing: combinedList,
      financial: estimateLeasingOptions,
      creditRankUtilized: leasingOptions?.creditRankUtilized,
    };
    try {
      return sendForEstimate(estimateData);
    } catch (err) {
      error(err?.[0]?.message);
    }
  };

  const saveToQuote = async (
    leasingOptions,
    chosenOptions,
    assetInformation,
    customerInformation,
    estimateLeasingOptions,
    workflowId,
    creditRankUtilized
  ) => {
    if (workflowId) {
      // quote-request w/ id
      const res = await updateEstimateData(
        leasingOptions,
        chosenOptions,
        assetInformation,
        { orgId: customerInformation },
        estimateLeasingOptions,
        creditRankUtilized,
        workflowId
      );
      if (!res) {
        return null;
      }
      handleRedirect('quote-request', workflowId);
    } else {
      try {
        const res = await saveEstimateData(
          leasingOptions,
          chosenOptions,
          assetInformation,
          customerInformation,
          estimateLeasingOptions
        );
        // quote-request w/ id
        handleRedirect('quote-request', res?.payload?.id);
      } catch (err) {
        error(err?.[0]?.message);
      }
    }
  };

  const generateQuote = async () => {};

  const printEstimate = async (
    leasingOptions,
    chosenOptions,
    assetInformation,
    customerInformation,
    estimateLeasingOptions,
    workflowId,
    setWorkflowId
  ) => {
    if (!workflowId) {
      try {
        const res = await saveEstimateData(
          leasingOptions,
          chosenOptions,
          assetInformation,
          customerInformation,
          estimateLeasingOptions
        );
        setWorkflowId(res?.payload?.id);
        window.open(`${BASE_URL}/v1/dealer/estimate/${res?.payload?.id}/pdf`);
      } catch (err) {
        error(err?.[0]?.message);
      }
    }
    if (workflowId) {
      const leasingArr = leasingOptions.leaseOptions;
      const { creditRankUtilized } = leasingOptions;
      try {
        await updateEstimateData(
          leasingArr,
          chosenOptions,
          assetInformation,
          customerInformation,
          estimateLeasingOptions,
          creditRankUtilized,
          workflowId
        );
        window.open(`${BASE_URL}/v1/dealer/estimate/${workflowId}/pdf`);
      } catch (err) {
        error(err?.[0]?.message);
      }
    }
    return null;
  };

  const saveUpdateEstimate = async (
    leasingOptions,
    chosenOptions,
    assetInformation,
    customerInformation,
    estimateLeasingOptions,
    workflowId,
    setWorkflowId
  ) => {
    if (workflowId) {
      const leasingArr = leasingOptions.leaseOptions;
      const { creditRankUtilized } = leasingOptions;
      try {
        return await updateEstimateData(
          leasingArr,
          chosenOptions,
          assetInformation,
          customerInformation,
          estimateLeasingOptions,
          creditRankUtilized,
          workflowId
        );
      } catch (err) {
        error(err?.[0]?.message);
      }
    }
    try {
      const res = await saveEstimateData(
        leasingOptions,
        chosenOptions,
        assetInformation,
        customerInformation,
        estimateLeasingOptions
      );
      setWorkflowId(res?.payload?.id);
      return res;
    } catch (err) {
      error(err?.[0]?.message);
    }
  };

  const enrollForQuote = async (
    leasingOptions,
    chosenOptions,
    assetInformation,
    customerInformation,
    estimateLeasingOptions
  ) => {
    try {
      const res = await saveEstimateData(
        leasingOptions,
        chosenOptions,
        assetInformation,
        customerInformation,
        estimateLeasingOptions
      );
      // start-enrollment w/ wid
      handleRedirect('start-enrollment', res?.payload?.id);
    } catch (err) {
      error(err?.[0]?.message);
    }
  };

  const handleCancelEstimate = async (workflowId, redirectType) => {
    if (workflowId) {
      try {
        await cancelEstimate(workflowId);
      } catch (err) {
        error(err?.[0]?.message);
      }
    }
    // add new param to force a refresh and timeout for cancel workflow to process
    setTimeout(() => {
      handleRedirect(redirectType, null, { refreshCache: true });
    }, 900);
  };

  const getCreditContractData = async orgId => {
    if (orgId) {
      try {
        const res = await getOrgInfo(orgId);
        const creditAssetTypesArray =
          res?.payload?.credit?.creditAssetTypes || [];
        setCreditAssetTypes(
          creditAssetTypesArray?.filter(type => type.approvedForLease === true)
        );
        setCreditContractStatus(
          [
            !!res?.payload?.data?.contractStartDate,
            !!res?.payload?.data?.credit?.info?.reasonCode,
          ].every(x => x === true)
        );
      } catch (err) {
        error(err?.[0]?.message);
      }
    } else {
      setCreditContractStatus(null);
      setCreditAssetTypes();
    }
    return null;
  };

  return {
    generateQuote,
    printEstimate,
    saveUpdateEstimate,
    updateEstimateData,
    saveToQuote,
    enrollForQuote,
    handleCancelEstimate,
    getCreditContractData,
    creditContractStatus,
    creditAssetTypes,
  };
};

export default useEstimator;
