/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import CommonColors from '@holmanfm/lib/common/colors';
import usePermissions from '@holmanfm/lib/hooks/use-permissions';
import { formatDate, formatDateTime } from '@holmanfm/lib/util/moment';
import { formatBySystemPrefix } from '@holmanfm/lib/common/service-request-helpers';
import { purchaseTypeConvert } from '@holmanfm/lib/common/marketplace/asset';
import Features from '@holmanfm/lib/lib-global';
import { useSelector } from '@holmanfm/lib/redux';
import { selectors } from '@holmanfm/lib/redux/reducers/index.reducer';
import { useNSTranslation } from '@holmanfm/lib/lang';
import { formatPhoneNumber } from 'react-phone-number-input';
import { isEmptyObject } from '@holmanfm/lib/util/helpers';
import makeStyles from '~/shared/components/makeStyles';
import Typography from '~/shared/components/atom/typography/typography';
import typeStyles from '~/shared/components/styles/global-type-styles';
import TabGroup from '~/areas/individual-assets/tab-group';
import AssetServicesEnrollment from './asset-services-enrollment';
import Button from '~/shared/components/atom/button';
import GenericNoDisplay from '~/areas/individual-assets/generic-nothing-to-display';

const useStyles = makeStyles(theme => ({
  columnGroup: {
    marginBottom: theme.spacing(1),
  },
  group: {
    minWidth: 162,
    marginRight: theme.spacing(4),
  },
  tabGroup: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.backgroundColors.tan}`,
    marginBottom: theme.spacing(3),
  },
  nestedGroups: {
    display: 'flex',
    justifyContent: 'space-between',
    '& div:not(:last-child)': {
      marginRight: theme.spacing(2),
    },
    '& > div': {
      width: '100%',
    },
  },
  tanContainer: {
    background: theme.palette.backgroundColors.tan,
    padding: theme.spacing(2),
  },
  assetCreationInfo: {
    marginTop: theme.spacing(3),
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
    },
  },
  additionalInfoLeft: {
    color: theme.palette.grey[600],
  },
}));

const TabColumns = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.group}>{children}</div>;
};

TabColumns.propTypes = {
  children: PropTypes.node.isRequired,
};

const TabItems = ({ data, header, style }) => {
  const classes = useStyles();
  const typeClasses = typeStyles();
  return (
    <div className={classes.columnGroup}>
      <Typography variant="subtitle1" className={typeClasses.driveBlue}>
        {header}
      </Typography>
      {style ? <span style={style}>{data || '—'}</span> : data || '—'}
    </div>
  );
};

TabItems.propTypes = {
  data: PropTypes.node,
  header: PropTypes.string.isRequired,
  style: PropTypes.shape({}),
};

TabItems.defaultProps = {
  style: undefined,
  data: undefined,
};

const red = CommonColors.sharedPalette.error.main;
const fieldGreen = CommonColors.sharedPalette.tertiary.main;

const OverviewTab = props => {
  const { asset, setHasDataUpdated, hasDataUpdated, parentOrg } = props;
  const classes = useStyles();
  const orgNodesFlattened = useSelector(selectors.getOrgNodes);
  const auxData = useSelector(selectors.getAssetAuxMetadata) || {};
  const { t } = useNSTranslation('individualAsset', 'overview');
  const { canAccessFeature } = usePermissions();

  const noUserFound = t('no-user-found');

  const excludeServices = asset?.excludeServices || [];

  const activeString = asset?.active ? t('active') : t('inactive');
  const statusColor = asset?.active
    ? { color: fieldGreen, fontWeight: 'bold' }
    : { color: red, fontWeight: 'bold' };

  const findAssetLocation = id => {
    if (id?.length > 0) {
      const selectedNode = orgNodesFlattened.filter(x => x.id === id);
      return selectedNode[0]?.node_label;
    }
    return '—';
  };
  const isCompleteAddress = address => {
    return (
      address?.street1 && address?.city && address?.state && address?.zipcode
    );
  };

  const makeColumns = obj => {
    const makeArr = Object.entries(obj);
    return makeArr.reduce((acc, item, index) => {
      const group = index % 2;
      const groupSize = acc.length;
      if (group === 0) {
        acc.push({ [item[0]]: item[1].name });
      } else {
        const currentGroup = acc[groupSize - 1];
        acc[groupSize - 1] = Object.assign(currentGroup, {
          [item[0]]: item[1].name,
        });
      }
      return acc;
    }, []);
  };

  return (
    <div>
      <Typography variant="h5" style={{ padding: '24px 0' }}>
        {t('title')}
      </Typography>
      {!parentOrg &&
        asset?.third_party_lease_type === '4' &&
        !asset?.wfids?.bd &&
        canAccessFeature(Features.BUYDIRECT) && (
          <div style={{ marginBottom: 16 }}>
            <Button
              variant="outlined"
              color="secondary"
              href={`/my-services/remarketing/buydirect/${asset.id}`}
            >
              {t('request-buydirect')}
            </Button>
          </div>
        )}
      {asset?.wfids?.bd && (
        <div style={{ marginBottom: 16 }}>
          <Button
            variant="outlined"
            color="secondary"
            href={`/my-services/remarketing/buydirect/status/${asset?.wfids?.bd}`}
          >
            {t('view-buydirect-request')}
          </Button>
        </div>
      )}
      <TabGroup
        sectionTitle={t('full-asset-details')}
        layout="asset-details"
        data={asset}
        setHasDataUpdated={setHasDataUpdated}
        hasDataUpdated={hasDataUpdated}
        parentOrg={parentOrg}
      >
        <>
          <TabColumns>
            <TabItems data={asset?.asset_no} header={t('asset-no')} />
            <TabItems data={asset?.vin} header={t('vin')} />
            <TabItems
              data={findAssetLocation(asset?.org_node_id)}
              header={t('asset-location')}
            />
            <TabItems
              data={activeString}
              header={t('asset-status')}
              style={statusColor}
            />
            <TabItems
              data={asset?.latest_odometer || '—'}
              header={t('current-odometer')}
            />
          </TabColumns>
          <TabColumns>
            <TabItems data={asset?.year} header={t('year')} />
            <TabItems data={asset?.make} header={t('make')} />
            <TabItems data={asset?.model} header={t('model')} />
            <TabItems data={asset?.trim || '—'} header={t('trim')} />
            <TabItems data={asset?.series || '—'} header={t('series')} />
          </TabColumns>
          <TabColumns>
            <TabItems
              data={asset?.vehicle_type || asset?.bodyType}
              header={t('vehicle-type')}
            />
            <TabItems
              data={asset?.exterior_color || '—'}
              header={t('exterior-color')}
            />
            <TabItems
              data={asset?.interior_color || '—'}
              header={t('interior')}
            />
            <TabItems
              data={asset?.assetGroupName || '—'}
              header={t('asset-group')}
            />
          </TabColumns>
          <TabColumns>
            <TabItems data={asset?.mis || ''} header={t('mis')} />
            <TabItems
              data={asset?.order_date ? formatDate(asset?.order_date) : '—'}
              header={t('order-date')}
            />
            <TabItems data={asset?.order_type} header={t('order-type')} />
            <TabItems
              data={formatDate(asset?.created_at)}
              header={t('created-date')}
            />
          </TabColumns>
          <TabColumns>
            <TabItems
              data={asset?.fuel_type || ''}
              header={t('fuel-capacity-uom')}
            />
            <TabItems
              data={asset?.fuel_capacity || ''}
              header={t('fuel-capacity')}
            />
            {asset?.device_id && (
              <TabItems
                data={asset?.device_id}
                header={t('geotab-device-id')}
              />
            )}
            <TabItems
              data={asset?.customer_asset_status}
              header={t('custom-status')}
            />
            <TabItems
              data={purchaseTypeConvert[asset?.third_party_lease_type]}
              header={t('purchase-type')}
            />
          </TabColumns>
        </>
      </TabGroup>
      <div className={classes.nestedGroups}>
        <TabGroup
          sectionTitle={t('registration-information')}
          layout="registration-info"
          data={asset}
          style={{ flex: 1 }}
          setHasDataUpdated={setHasDataUpdated}
          hasDataUpdated={hasDataUpdated}
          parentOrg={parentOrg}
        >
          <>
            <TabColumns>
              <TabItems
                data={asset?.license_state}
                header={t('license-plate-state')}
              />
              <TabItems
                data={asset?.license_plate}
                header={t('license-plate')}
              />
              {asset?.registeredAddress &&
                isCompleteAddress(asset?.registeredAddress) && (
                  <TabItems
                    data={`${asset?.registeredAddress.street1}, ${asset?.registeredAddress.city} ${asset?.registeredAddress.state}, ${asset?.registeredAddress.zipcode}`}
                    header={t('registered-address')}
                  />
                )}
            </TabColumns>
            <TabColumns>
              <TabItems
                data={
                  asset?.registration_renew_date
                    ? formatDate(asset?.registration_renew_date)
                    : ''
                }
                header={t('registration-exp-date')}
              />
              <TabItems
                data={asset?.tempTagNumber}
                header={t('temp-tag-number')}
                style={{ color: 'grey' }}
              />
            </TabColumns>
          </>
        </TabGroup>
        <TabGroup
          sectionTitle={t('driver-information')}
          layout="driver-info"
          data={asset}
          style={{ flex: 2 }}
          setHasDataUpdated={setHasDataUpdated}
          hasDataUpdated={hasDataUpdated}
          parentOrg={parentOrg}
        >
          <>
            <TabColumns>
              <TabItems
                data={
                  asset?.selected_user?.firstName &&
                  asset?.selected_user?.lastName
                    ? `${asset?.selected_user?.firstName} ${asset?.selected_user?.lastName}`
                    : '—'
                }
                header={t('assigned-driver')}
              />
            </TabColumns>
            <TabColumns>
              <TabItems
                data={
                  asset?.selected_user?.phoneNumber
                    ? formatPhoneNumber(asset?.selected_user?.phoneNumber)
                    : '—'
                }
                header={t('driver-phone-number')}
              />
            </TabColumns>
            <TabColumns>
              <TabItems
                data={asset?.selected_user?.email || '—'}
                header={t('driver-email-address')}
              />
            </TabColumns>
          </>
        </TabGroup>
      </div>
      <div className={classes.nestedGroups}>
        <TabGroup
          sectionTitle={t('customized-data-fields')}
          layout="aux"
          data={asset}
          style={{ flex: 2 }}
          setHasDataUpdated={setHasDataUpdated}
          hasDataUpdated={hasDataUpdated}
          parentOrg={parentOrg}
          auxData={auxData}
        >
          {!isEmptyObject(auxData) ? (
            makeColumns(auxData)?.map(d => (
              <TabColumns key={Object.keys(d)}>
                {Object.entries(d)?.map(v => (
                  <TabItems
                    key={v[0]}
                    data={asset?.assetExtend?.[v[0]] || null}
                    header={v[1]}
                  />
                ))}
              </TabColumns>
            ))
          ) : (
            <GenericNoDisplay category={t('custom-no-data')} />
          )}
        </TabGroup>
      </div>
      {!parentOrg &&
        asset?.third_party_lease_type === '4' &&
        Array.isArray(excludeServices) &&
        excludeServices.length <= 0 && (
          <div className={classes.nestedGroups}>
            <AssetServicesEnrollment
              savedSelection={excludeServices}
              asset={asset}
              setHasDataUpdated={setHasDataUpdated}
              hasDataUpdated={hasDataUpdated}
            />
          </div>
        )}
      <div className={classes.assetCreationInfo}>
        <div className={classes.additionalInfoLeft}>
          {asset?.created_at && (
            <div style={{ marginRight: 16 }}>
              <Typography variant="caption">
                <strong>{t('created-by')} </strong>
                {formatBySystemPrefix(
                  asset?.created_by,
                  t('system'),
                  noUserFound
                )}{' '}
                {formatDateTime(asset?.created_at)}
              </Typography>
            </div>
          )}
          {asset?.updated_at && (
            <Typography variant="caption">
              <strong>{t('last-updated-by')} </strong>
              {formatBySystemPrefix(
                asset?.updated_by,
                t('system'),
                noUserFound
              )}{' '}
              {formatDateTime(asset?.updated_at)}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

OverviewTab.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setHasDataUpdated: PropTypes.func.isRequired,
  hasDataUpdated: PropTypes.bool.isRequired,
  parentOrg: PropTypes.bool,
};

OverviewTab.defaultProps = {
  parentOrg: false,
};

export default OverviewTab;
