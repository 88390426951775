import React from 'react';
import PropTypes from 'prop-types';
import { useNSTranslation } from '@holmanfm/lib/lang';
import Typography from '~/shared/components/atom/typography';
import Dialog from '~/shared/components/dialog';
import Slide from '~/shared/components/transitions/slide';
import Toolbar from '~/shared/components/bar/toolbar';
import IconButton from '~/shared/components/atom/icon-button';
import Close from '~/shared/icons/close';
import makeStyles from '~/shared/components/makeStyles';
import DialogActions from '~/shared/components/dialog-actions';
import DialogContent from '~/shared/components/dialog-content';
import Button from '~/shared/components/atom/button';
import ErrorOutline from '~/shared/icons/error-outline';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    whiteSpace: 'pre-line',
  },
  toolbar: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 12,
    },
  },
  iconStyle: {
    color: theme.palette.error.main,
    fontSize: 38,
    marginRight: theme.spacing(1),
  },
  iconHolder: {
    lineHeight: '.65rem',
    color: theme.palette.error.main,
  },
  errorMessage: {
    color: theme.palette.error.main,
    padding: theme.spacing(0, 1),
  },
  buttonPadding: {
    marginTop: theme.spacing(4),
    paddingRight: 12,
    paddingBottom: 12,
  },
}));

const ErrorDialog = props => {
  const { open, closeDialog, error } = props;
  const classes = useStyles();
  const { t } = useNSTranslation('main', 'dialog');

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          onClick={closeDialog}
          aria-label="Close"
        >
          <Close />
        </IconButton>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
          }}
        >
          <Typography variant="h3" className={classes.iconHolder}>
            {t('error')}
          </Typography>
          <ErrorOutline className={classes.iconStyle} />
        </div>
      </Toolbar>
      <DialogContent>
        <div className={classes.errorMessage}>{error}</div>
      </DialogContent>
      <DialogActions>
        <div className={classes.buttonPadding}>
          <Button
            color="secondary"
            onClick={closeDialog}
            variant="contained"
            style={{ marginRight: 8 }}
          >
            {t('close')}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

ErrorDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
};

ErrorDialog.defaultProps = {
  error: undefined,
};

export default ErrorDialog;
