/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getAsset } from '@holmanfm/lib/services/assets';
import {
  getChildAssets,
  getChildFeatures,
  getChildOrgData,
} from '@holmanfm/lib/services/parent-org';
import { useParams, useLocation } from 'react-router-dom';
import useAsync from '@holmanfm/lib/hooks/use-async';
import usePermissions from '@holmanfm/lib/hooks/use-permissions';
import Features from '@holmanfm/lib/lib-global';
import HeaderData from '~/areas/individual-assets/header-data';
import OverviewTab from '~/areas/individual-assets/overview-tab';
import ServerMessages from '~/shared/components/messages/server-messages';
import CircularProgress from '~/shared/components/circular-progress';
import OdometerTab from '~/areas/individual-assets/odometer-tab';
import DocumentsPhotosTab from '~/areas/individual-assets/documents-photos';
import FuelManagementTab from '~/areas/individual-assets/fuel-management-tab';
import MaintenanceTab from '~/areas/individual-assets/maintenance-tab';
import Typography from '~/shared/components/atom/typography/typography';
import typeStyles from '~/shared/components/styles/global-type-styles';

const TabPanel = props => {
  const { children, value, selectedValue, ...rest } = props;
  return (
    <div hidden={value !== selectedValue} id={`request-tab-${value}`} {...rest}>
      {value === selectedValue && children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.shape({}).isRequired,
  selectedValue: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const IndividualAssets = props => {
  const { history } = props;
  const { assetNo, childOrgId } = useParams();
  const { data, run, status, error } = useAsync();
  const {
    data: childOrgData,
    run: childOrgRun,
    // error: childOrgError,
  } = useAsync();
  const { hash } = useLocation();
  const { canAccessFeature } = usePermissions();
  const typeClasses = typeStyles();

  const parentOrg = Boolean(childOrgId);

  // hashes == '#overview', '#fuel', '#maintenance', '#odometer', '#documents', '#financial'
  const [tabValue, setTabValue] = React.useState(
    hash?.replace('#', '') || 'overview'
  );

  useEffect(() => {
    if (hash === '#maintenance' && history?.location?.state) {
      // let the page scroll to the previously selected maintenance history record and then clear the state so it doesn't keep scrolling if they go to a different tab and come back to the maintenance tab
      setTimeout(() => {
        history.replace();
      }, 2000);
    }
  }, [hash, history]);

  const [hasDataUpdated, setHasDataUpdated] = React.useState(false);

  const [childFeatures, setChildFeatures] = React.useState();

  React.useEffect(() => {
    if (childOrgId) {
      const getChildFeaturesFunc = async () => {
        try {
          const features = await getChildFeatures(childOrgId);
          setChildFeatures(features.payload);
        } catch (err) {
          console.error(err);
        }
      };
      getChildFeaturesFunc();
    }
  }, [childOrgId]);

  const hasFuelService = parentOrg
    ? childFeatures?.includes(Features.FUEL_MANAGEMENT.INDEX)
    : canAccessFeature(Features.FUEL_MANAGEMENT.INDEX);
  const hasMaintenanceService = parentOrg
    ? childFeatures?.includes(Features.SERVICE_MAINT.INDEX)
    : canAccessFeature(Features.SERVICE_MAINT.INDEX);

  React.useEffect(() => {
    if (parentOrg) {
      run(getChildAssets(childOrgId, assetNo).then(res => res.payload));
    } else {
      run(getAsset(assetNo).then(res => res.payload));
    }
  }, [run, assetNo, hasDataUpdated, parentOrg, childOrgId]);

  React.useEffect(() => {
    if (childOrgId) {
      childOrgRun(getChildOrgData(childOrgId).then(res => res?.payload));
    }
  }, [childOrgRun, childOrgId]);

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
    // Replace URL hash so that the link is shareable
    history.replace(`${history?.location?.pathname}#${newValue}`);
  };

  if (status === 'rejected') {
    return <ServerMessages messages={error} />;
  }

  if (status === 'pending') {
    return <CircularProgress />;
  }

  if ((parentOrg && data) || (status === 'resolved' && data)) {
    return (
      <div>
        {parentOrg && (
          <Typography
            variant="h4"
            style={{ marginBottom: 16 }}
            className={typeClasses.freshBlue}
          >
            {childOrgData?.name}
          </Typography>
        )}
        <HeaderData
          data={data}
          handleTabChange={handleTabChange}
          tabValue={tabValue}
          hasFuelService={hasFuelService}
          hasMaintenanceService={hasMaintenanceService}
        />
        {/* Body Content */}
        <TabPanel value={tabValue} selectedValue="overview">
          {data && (
            <OverviewTab
              asset={data}
              setHasDataUpdated={setHasDataUpdated}
              hasDataUpdated={hasDataUpdated}
              parentOrg={parentOrg}
            />
          )}
        </TabPanel>
        <TabPanel value={tabValue} selectedValue="fuel">
          <FuelManagementTab
            asset={data}
            setHasDataUpdated={setHasDataUpdated}
            hasDataUpdated={hasDataUpdated}
            parentOrg={parentOrg}
            childOrgId={childOrgId}
          />
        </TabPanel>
        <TabPanel value={tabValue} selectedValue="maintenance">
          <MaintenanceTab
            asset={data}
            setHasDataUpdated={setHasDataUpdated}
            hasDataUpdated={hasDataUpdated}
            parentOrg={parentOrg}
            childOrgId={childOrgId}
          />
        </TabPanel>
        <TabPanel value={tabValue} selectedValue="odometer">
          <OdometerTab
            asset={data}
            parentOrg={parentOrg}
            childOrgId={childOrgId}
          />
        </TabPanel>
        {/* <TabPanel value={tabValue} index={4}>
          <div>Financial Content</div>
        </TabPanel> */}
        <TabPanel value={tabValue} selectedValue="documents">
          <DocumentsPhotosTab
            asset={data}
            setHasDataUpdated={setHasDataUpdated}
            hasDataUpdated={hasDataUpdated}
            parentOrg={parentOrg}
            childOrgId={childOrgId}
          />
        </TabPanel>
      </div>
    );
  }
};

export default IndividualAssets;
